// styles & assets
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

export default function CustomNav() {
  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
      <Container className="px-3">
        <Navbar.Brand href="/" className="">
          Sister Smiley
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}
