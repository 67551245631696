import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import CustomNav from './components/CustomNav'

import './App.css'
import PublicWitnessing from './pages/publicWitnessing/PublicWitnessing'
import PwDetails from './pages/pwDetails/PwDetails'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <CustomNav />
        <Routes>
          <Route path="/" element={<PublicWitnessing />} />
          <Route path="/daily-schedule" element={<Navigate to="/" />} />
          <Route path="/daily-schedule/:id" element={<PwDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
