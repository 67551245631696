import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCzstBESrfPlcRoDBH8i3SZXe-7bdlCFIQ',
  authDomain: 'sister-smiley.firebaseapp.com',
  projectId: 'sister-smiley',
  storageBucket: 'sister-smiley.appspot.com',
  messagingSenderId: '415541358671',
  appId: '1:415541358671:web:22b2ffcda054e0670f3a3a',
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, timestamp }
