import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { format } from 'date-fns'

// styles
import './PublicWitnessing.css'

// assets
export default function PublicWitnessing() {
  const [date, setDate] = useState(new Date())
  const today = format(date, 'd')
  const navigate = useNavigate()

  const handleClick = (date) => {
    setDate(date)
    // 2022-6-16
    const y = format(date, 'y')
    const m = format(date, 'L')
    const d = format(date, 'd')

    navigate(`/daily-schedule/${y}-${m}-${d}`)
  }

  return (
    <Container className="public-witnessing mb-4">
      <h2 className="text-center mt-3 mb-2">Sister Smiley</h2>
      <p className="text-center">Please choose a date below to get started</p>
      <hr />
      <Row>
        <Col md={10} lg={8} xl={6} className="mx-auto">
          <Calendar
            onClickDay={handleClick}
            value={date}
            calendarType="US"
            showFixedNumberOfWeeks={false}
            showNeighboringMonth={false}
            minDetail="month"
            nextLabel={
              <span className="material-symbols-outlined">chevron_right</span>
            }
            prevLabel={
              <span className="material-symbols-outlined">chevron_left</span>
            }
            next2Label={null}
            prev2Label={null}
            // minDate={new Date()}
            // tileContent={'hey'}
          />
          <hr />
          <Row>
            <Col className="d-flex justify-content-center">
              <span className="legend-today">{today}</span>
              <span>Today</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
